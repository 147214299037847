export const projects = {
	QiddiyaWaterThemePark: {
		id: 'QiddiyaWaterThemePark',
		title: 'Qiddiya Water Theme Park',
		contractor: 'Voltas',
		client: 'Al Qiddiya',
		clientImage: '/clients/client12.png',
		duration: '1 year',
		description:
			'Infrastructure work - supply & installation of storm water system/sewer Network/water supply system/fire fighting system/Chilled water Network/Electrical Installations including switch gear, transformers, Rmu. LV, WLV Ducting & Cable installations',
		furtherDescription:
			'Supply, Installation, Testing & commissioning of Infra-structure MEP works including Electrical Installations (Switch gear, Transformers, Rmu) and mechanical installations (Storm, sewerage, firefighting, potable water, Irrigation water, chilled water network with leak detection system, LPG network with LPH tanks & LV, ELV Ducting work.',
		tags: ['water system', 'infrastructure'],
		gallery: [
			'/projects/QiddiyaWaterThemePark/0.png',
			'/projects/QiddiyaWaterThemePark/1.png',
			'/projects/QiddiyaWaterThemePark/2.png',
			'/projects/QiddiyaWaterThemePark/3.png',
			'/projects/QiddiyaWaterThemePark/4.png',
			'/projects/QiddiyaWaterThemePark/5.png',
			'/projects/QiddiyaWaterThemePark/6.png',
			'/projects/QiddiyaWaterThemePark/7.png',
			'/projects/QiddiyaWaterThemePark/8.png',
		],
	},
	RoshanVillas: {
		id: 'RoshanVillas',
		title: 'Roshan Villas',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Roshan',
		clientImage: '/clients/client11.png',
		duration: '2 years',
		description:
			'Supply & installation of mechanical, electrical and air conditioning works (VRF), including the establishment and finishing of all internal and external works (600villas).',
		furtherDescription:
			'MEP installations, testing & commissioning of 300 luxury villas at Riyadh Front area including electrical, Plumbing, Telecom and HVAC (VRF system).',
		tags: ['VFR', 'villas'],
		gallery: [
			'/projects/RoshanVillas/1.png',
			'/projects/RoshanVillas/2.png',
			'/projects/RoshanVillas/3.png',
			'/projects/RoshanVillas/4.png',
			'/projects/RoshanVillas/5.png',
			'/projects/RoshanVillas/6.png',
			'/projects/RoshanVillas/7.png',
		],
	},
	AlBasateenVillas: {
		id: 'AlBasateenVillas',
		title: 'Al-Basateen Villas',
		contractor: 'Upmoc Company',
		client: 'AICC',
		clientImage: '/clients/client23.png',
		duration: '1 year',
		description:
			'Supply & installation of mechanical, electrical and air conditioning works (VRF), including the establishment and finishing of all internal and external works (600villas).',
		furtherDescription:
			'MEP installations, testing & commissioning of  25 luxury villas at Al Diriyah Distract including Electrical, Plumbing, Telecom and HVAC (VRF system). ',
		tags: ['VFR', 'villas'],
		gallery: [
			'/projects/AlBasateenVillas/1.png',
			'/projects/AlBasateenVillas/2.png',
			'/projects/AlBasateenVillas/3.png',
			'/projects/AlBasateenVillas/4.png',
			'/projects/AlBasateenVillas/5.png',
			'/projects/AlBasateenVillas/6.png',
			'/projects/AlBasateenVillas/7.png',
			'/projects/AlBasateenVillas/8.png',
			'/projects/AlBasateenVillas/9.png',
			'/projects/AlBasateenVillas/10.png',
		],
	},
	Exit10Development: {
		id: 'Exit10Development',
		title: 'Exit-10 Development',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Exit 10/ Seven',
		clientImage: '/clients/client11.png',
		duration: '1 year',
		description:
			'Supply & installation of mechanical and electrical works, including grounding, boards and foundation works, with central air conditioning system and firefighting.',
		furtherDescription:
			'Exit 10 ( Seven ) Projet, MEP Services including (Electrical, Earthing, and Plumbing works).',
		tags: ['Mechanical', 'Electrical'],
		gallery: [
			'/projects/Exit10Development/1.png',
			'/projects/Exit10Development/2.png',
			'/projects/Exit10Development/3.png',
			'/projects/Exit10Development/4.png',
			'/projects/Exit10Development/5.png',
			'/projects/Exit10Development/6.png',
			'/projects/Exit10Development/7.png',
			'/projects/Exit10Development/8.png',
			'/projects/Exit10Development/9.png',
			'/projects/Exit10Development/10.png',
		],
	},
	SportsCityAlKharj: {
		id: 'SportsCityAlKharj',
		title: 'Al Kharj Sports Complex',
		contractor: 'Fan Al Me’maar',
		client: 'Ministry of Sports',
		clientImage: '/clients/client27.png',
		duration: '',
		description:
			'Supply and installation of electromechanical structural and architectural works.',
		furtherDescription: '',
		tags: ['Electromechanical', 'Structural'],
		gallery: [
			'/projects/SportsCityAlKharj/1.png',
			'/projects/SportsCityAlKharj/2.png',
		],
	},
	Neom: {
		id: 'Neom',
		title: 'Al-Owaidah Palace',
		contractor: 'Shapoorji Pallonji Mideast',
		client: 'Neom',
		clientImage: '/clients/client8.png',
		duration: '1 year',
		description:
			'Installation, testing, and commissioning infrastructure works (plumbing, fire fighting, medium voltage, low voltage, switch gears, and transformers.',
		furtherDescription: '',
		tags: ['Infrastructure'],
		gallery: [
			'/projects/Neom/1.png',
			'/projects/Neom/2.png',
			'/projects/Neom/3.png',
			'/projects/Neom/4.png',
			'/projects/Neom/5.png',
			'/projects/Neom/6.png',
			'/projects/Neom/7.png',
			'/projects/Neom/8.png',
		],
	},
	GoldenChickenFactory: {
		id: 'GoldenChickenFactory',
		title: 'Golden Chicken Factory',
		contractor: 'York Company',
		client: 'Johnson Control / Golden Chicken',
		clientImage: '/clients/client13.png',
		duration: '6 Months',
		description:
			'Design & implementation of central AC works in the factory, including installation of units with connections, extensions and operation.',
		furtherDescription:
			'Supply and Installation of Chilled Water Network, Ventilation System, and DX Units for separate Buildings, VRF System, and Control System Works for all the mentioned.',
		tags: ['Factory'],
		gallery: [
			'/projects/GoldenChickenFactory/1.png',
			'/projects/GoldenChickenFactory/2.png',
			'/projects/GoldenChickenFactory/3.png',
			'/projects/GoldenChickenFactory/4.png',
			'/projects/GoldenChickenFactory/5.png',
			'/projects/GoldenChickenFactory/6.png',
			'/projects/GoldenChickenFactory/7.png',
			'/projects/GoldenChickenFactory/8.png',
			'/projects/GoldenChickenFactory/9.png',
		],
	},
	SalbookhAirbase: {
		id: 'SalbookhAirbase',
		title: 'Improvements of Salbookh Airbase',
		contractor: '',
		client: 'BSS',
		clientImage: '/clients/client14.png',
		duration: '2 years',
		description: 'Civil works for open storm drainage channels.',
		furtherDescription: 'Civil works for open storm drainage channels.',
		tags: ['Storm Drainage'],
		gallery: [
			'/projects/SalbookhAirbase/1.png',
			'/projects/SalbookhAirbase/2.png',
		],
	},
	IslamicComplexCourts: {
		id: 'IslamicComplexCourts',
		title: 'Islamic Complex Courts',
		contractor: 'Al-saghyir Company',
		client: 'Ministry of Justice',
		clientImage: '/clients/client3.png',
		duration: '',
		description: 'Al Madinah Islamic complex courts',
		furtherDescription:
			'Design, supply, installation and maintenance of fire network and central air conditioning.',
		tags: ['Infrastructure', 'Fire network'],
		gallery: [
			'/projects/IslamicComplexCourts/1.png',
			'/projects/IslamicComplexCourts/2.png',
			'/projects/IslamicComplexCourts/3.png',
			'/projects/IslamicComplexCourts/4.png',
			'/projects/IslamicComplexCourts/5.png',
			'/projects/IslamicComplexCourts/6.png',
			'/projects/IslamicComplexCourts/7.png',
		],
	},
	HajarSportsStadium: {
		id: 'HajarSportsStadium',
		title: 'Hajar Sports Stadium',
		contractor: 'Saudi Building Technic Co',
		client: 'Youth Care',
		clientImage: '/clients/client4.png',
		duration: '',
		description: 'Central air conditioning, fire works, and electrical works',
		furtherDescription:
			'Civil and architectural finishing works and epoxy coating. General site works and asphalt.',
		tags: ['Air Conditioning', 'Fire', 'Electrical'],
		gallery: [
			'/projects/HajarSportsStadium/1.png',
			'/projects/HajarSportsStadium/2.png',
			'/projects/HajarSportsStadium/3.png',
			'/projects/HajarSportsStadium/4.png',
			'/projects/HajarSportsStadium/5.png',
			'/projects/HajarSportsStadium/6.png',
		],
	},
	DiplomaticQuarterGardens: {
		id: 'DiplomaticQuarterGardens',
		title: 'Diplomatic Quarter Gardens',
		contractor: 'ENJAZ for Operation & Maintenance',
		client: 'Royal Commission for Riyadh City.',
		clientImage: '/clients/client29.png',
		duration: '',
		description: 'The rehabilitation of the diplomatic quarter gardens',
		furtherDescription:
			'Replacement of MEP Networks & Architecture Works Stainless Steel Seats, Trees Protection Covers, Pavements, Interlock,..etc.',
		tags: ['Gardens'],
		gallery: [
			'/projects/DiplomaticQuarterGardens/1.png',
			'/projects/DiplomaticQuarterGardens/2.png',
			'/projects/DiplomaticQuarterGardens/3.png',
		],
	},
	KidsGardensPlaysets: {
		id: 'KidsGardensPlaysets',
		title: 'Kids Gardens Playsets',
		contractor: 'ROS for Trading & Contracting',
		client: 'Riyadh Region Municipality',
		clientImage: '/clients/client30.png',
		duration: '',
		description: 'Replacement of Kids Gardens Playsets in Riyadh City.',
		furtherDescription:
			'Replacement of kids garden playsets, fitness games & rubber playgrounds including all related civil works',
		tags: ['Kids', 'Playsets'],
		gallery: [
			'/projects/KidsGardensPlaysets/1.png',
			'/projects/KidsGardensPlaysets/2.png',
			'/projects/KidsGardensPlaysets/3.png',
			'/projects/KidsGardensPlaysets/4.png',
			'/projects/KidsGardensPlaysets/5.png',
			'/projects/KidsGardensPlaysets/6.png',
		],
	},

	// z: {
	// 	id: '',
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '/clients/client.png',
	// 	duration: '',
	// 	description: '',
	// 	furtherDescription: '',
	// 	tags: [],
	// 	gallery: [],
	// },
};

export const otherProjects = {
	AlOwaidahPalace: {
		id: 'AlOwaidahPalace',
		title: 'Al-Owaidah Palace',
		contractor: 'York Company',
		client: 'Private',
		clientImage: '/clients/client28.png',
		description:
			'Supply, installation, replacement and renewal of the central air conditioning system.',
	},
	MinistryOfInteriorForensic: {
		id: 'MinistryOfInteriorForensic',
		title: 'Ministry of Interior forensic evidence building',
		client: 'Ministry of Interior',
		clientImage: '/clients/client6.png',
		description:
			'Civil works. Finishing works. Electromechanical works. General site works',
		location: 'Medina, Saudi Arabia',
	},
	TrafficBuilding: {
		id: 'TrafficBuilding',
		title: 'Traffic Building',
		client: 'Ministry of Interior',
		clientImage: '/clients/client6.png',
		description: 'Civil works. Finishing works. General site works',
		location: 'Al Khubair, Saudi Arabia',
	},
	CivilDefenseBuilding: {
		id: 'CivilDefenseBuilding',
		title: 'Civil Defense Building',
		contractor: '',
		client: 'Ministry of Interior',
		clientImage: '/clients/client6.png',
		description: 'Civil works. Finishing works. General site works',
	},
	GrainSilos: {
		id: 'GrainSilos',
		title: 'Grain Silos',
		contractor: '',
		client: 'The General Authority of Grain Silos',
		clientImage: '/clients/client15.png',
		description:
			'Finishing works for the administrative building Sales building and cafeteria',
		location: 'Al Ahsa, Saudi Arabia',
	},
	WasteLandfillProject: {
		id: 'WasteLandfillProject',
		title: 'Waste landfill project',
		contractor: '',
		client: 'Ministry of Environment',
		clientImage: '/clients/client5.png',
		description:
			'Lining and isolating lakes with backfill Tank construction works Mechanical extension works',
		location: 'Riyadh, Saudi Arabia',
	},
	ResidentialComplexesProject: {
		id: 'ResidentialComplexesProject',
		title: 'Residential complexes project',
		contractor: '',
		client: 'The Royal Guard',
		clientImage: '/clients/client10.png',
		description:
			'Civil works for buildings Mechanical and electrical extension works',
		location: 'Riyadh, Saudi Arabia',
	},
	WaterTanksConveyorLines: {
		id: 'WaterTanksConveyorLines',
		title: 'Water Tanks and Conveyor Lines',
		contractor: '',
		client: 'Ministry of Water',
		clientImage: '/clients/client5.png',
		description:
			'Civil and architectural finishing works and epoxy coating. External works and road works.',
		location: 'Al Majmaah Governorate, Saudi Arabia',
	},
	WaterTanksConveyorLines2: {
		id: 'WaterTanksConveyorLines2',
		title: 'Water Tanks and Conveyor Lines',
		contractor: '',
		client: 'Ministry of Water',
		clientImage: '/clients/client5.png',
		description: 'Civil and architectural finishing works and epoxy coating.',
		location: 'Dawadmi Governorate, Saudi Arabia',
	},
	WaterTanksConveyorLines3: {
		id: 'WaterTanksConveyorLines3',
		title: 'Water Tanks and Conveyor Lines',
		contractor: '',
		client: 'Ministry of Water',
		clientImage: '/clients/client5.png',
		description:
			'Civil and architectural finishing works and epoxy coating. General site works and asphalt.',
		location: 'Al-Aflaj Governorate, Saudi Arabia',
	},
	WaterTanksConveyorLines4: {
		id: 'WaterTanksConveyorLines4',
		title: 'Water Tanks and Conveyor Lines',
		contractor: '',
		client: 'Ministry of Water',
		clientImage: '/clients/client5.png',
		description: 'Civil and architectural finishing works and epoxy coating.',
		location: 'Al Buwaib, Saudi Arabia',
	},
	KingAbdelazizUniversity: {
		id: 'KingAbdelazizUniversity',
		title: 'Faculty Housing – King Abdelaziz University',
		contractor: '',
		client: 'Ministry of Higher Education',
		clientImage: '/clients/client1.png',
		description: 'Civil and architectural finishing works on site.',
		location: 'Jeddah, Saudi Arabia',
	},
	SewagePlant: {
		id: 'SewagePlant',
		title: 'Sewage Plant Project',
		contractor: '',
		client: 'Cities Authority',
		clientImage: '',
		description: 'Finishing works, waterproofing and epoxy coating for tanks.',
		location: 'Medina, Saudi Arabia',
	},
	AlZamilCartonFactory: {
		id: 'AlZamilCartonFactory',
		title: 'Al-Zamil Carton Factory',
		contractor: '',
		client: 'UCIC',
		clientImage: '/clients/client18.png',
		description:
			'Central air conditioning in the factory, including water cooler station with pumps, air handling units - 787 units, ventilation fans. Complete firefighting network.',
		location: '',
	},
	MedicalWarehouseConstructions: {
		id: 'MedicalWarehouseConstructions',
		title: 'Medical Warehouse Constructions (King Saud University)',
		contractor: '',
		client: 'Ministry of Higher Education',
		clientImage: '/clients/client1.png',
		description:
			'Complete central air conditioning works in warehouses, including combined and separate air conditioning units, all air duct work, ventilation fans. Complete fire network works. Sanitary works.',
		location: 'Riyadh, Saudi Arabia',
	},
	HousingAuthorityWorkers: {
		id: 'HousingAuthorityWorkers',
		title: 'Housing for Authority’s Workers',
		contractor: '',
		client: 'Royal Commission',
		clientImage: '',
		description: 'Finishing works, waterproofing, and epoxy coating for tanks.',
		location: 'Jubail, Saudi Arabia',
	},
	VillasEasternRegion: {
		id: 'VillasEasternRegion',
		title: 'Villas in the Eastern Region',
		contractor: '',
		client: 'Aramco',
		clientImage: '/clients/client32.jpeg',
		description:
			'Central air conditioning system and fire works - firefighting network. Electrical Works.',
		location: 'Jubail, Saudi Arabia',
	},
	FacilitiesBuildingProject: {
		id: 'FacilitiesBuildingProject',
		title: 'Facilities Building Project',
		contractor: '',
		client: 'Accompanying',
		clientImage: '',
		description:
			'Central air conditioning system and fire works - firefighting network. Electrical Works.',
		location: 'Jubail, Saudi Arabia',
	},
	KingKhaledHospital: {
		id: 'KingKhaledHospital',
		title: 'King Khaled Hospital',
		contractor: '',
		client: 'Ministry of Higher Education',
		clientImage: '/clients/client1.png',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Abha, Saudi Arabia',
	},
	AbhaPrivateHospital: {
		id: 'AbhaPrivateHospital',
		title: 'Abha Private Hospital',
		contractor: '',
		client: 'Private',
		clientImage: '',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Abha, Saudi Arabia',
	},
	CourtBuilding: {
		id: 'CourtBuilding',
		title: 'Court Building',
		contractor: '',
		client: 'Ministry of Justice',
		clientImage: '/clients/client3.png',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Medina, Saudi Arabia',
	},
	ForensicEvidenceBuilding: {
		id: 'ForensicEvidenceBuilding',
		title: 'Forensic Evidence Building',
		contractor: '',
		client: 'Ministry of Interior',
		clientImage: '/clients/client6.png',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Hail, Saudi Arabia',
	},
	ResidentialComplexes: {
		id: 'ResidentialComplexes',
		title: 'Residential Complexes - General Authority for Water Transfer',
		contractor: '',
		client: 'Water Desalination Authority',
		clientImage: '/clients/client31.png',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Medina, Saudi Arabia',
	},
	WaterStation: {
		id: 'WaterStation',
		title: 'Water Station',
		contractor: '',
		client: 'Cities Authority',
		clientImage: '/clients/client33.jpg',
		description: 'Central air conditioning and fire works. Electrical Works.',
		location: 'Sudair Industrial And Business City, Saudi Arabia',
	},

	// {
	//   id: '8',
	//   title: 'King Khaled Hospital Project - Abha',
	//   contractor: '',
	//   client: 'Ministry of Higher Education',
	//   clientImage: '/clients/client36.png',
	//   description: 'Central air conditioning and fire works. Electrical Works.',
	//   location: 'Abha',
	// },
	// {
	//   id: '9',
	//   title: 'Medical Warehouse Constructions (King Saud University, Riyadh)',
	//   contractor: '',
	//   client: 'Ministry of Higher Education',
	//   clientImage: '/clients/client37.png',
	//   description: 'Central air conditioning and fire works. Electrical Works.',
	//   location: 'Riyadh',
	// },
	// lastt
	TahliaHousing: {
		id: 'TahliaHousing',
		title: 'Tahlia Housing',
		contractor: 'Al-soumly Company',
		client: 'The General Authority for Water Desalination',
		clientImage: '/clients/client31.png',
		description:
			'Supply and installation of fire works in the general site, buildings and project buildings with a fire pump station.',
		location: 'Riyadh, Saudi Arabia',
	},
	// id: {
	// 	id: '',
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '/clients/client31.png',
	// 	description:
	// 		'',
	// 	location: '',
	// },
	// t: {
	// 	id: '',
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '/clients/client.png',
	// 	description: '',
	// },
	// t: {
	// 	id: '',
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '/clients/client.png',
	// 	description: '',
	// },
	// t: {
	// 	id: '',
	// 	title: '',
	// 	contractor: '',
	// 	client: '',
	// 	clientImage: '/clients/client.png',
	// 	description: '',
	// },
};

/*
	KingSalmanAirbase: {
			id: 'KingSalmanAirbase',
			title: '',
			contractor: '',
			client: 'Al Bawani',
			clientImage: '/clients/client.png',
			duration: '2 years',
			description: 'Curbstone, Plaster, Paints, Block, Tiles, and Epoxy Coating works as a package for King Salman Airbase at Dirab City. ',
			furtherDescription: '',
			tags: [],
			gallery: [],
		},

*/
