import { useSearchParams } from 'react-router-dom';
import { otherProjects, projects } from '../../data/projects';

const ProjectCard = (props) => {
	let [, setSearchParams] = useSearchParams();

	const {
		id,
		tags,
		title,
		client,
		duration,
		gallery,
		clientImage,
		description,
	} = props;
	const homeImage = gallery?.[0];

	const navigateToProject = () => setSearchParams({ active: 'projects', id });

	return (
		<div
			onClick={navigateToProject}
			className='flex h-[38rem] w-full flex-col overflow-hidden rounded-xl bg-[#f6f6f6] p-0 font-poppins text-[#3B3B3B] shadow-[1px_4px_4px_1px_rgba(0,0,0,0.25)] md:h-[32rem] lg:h-full'>
			<div className='relative h-[250px] max-h-[250px] w-full rounded-t-xl'>
				<img
					width={200}
					height={200}
					src={homeImage}
					alt={title + 'header-image'}
					className='h-full w-full rounded-t-xl object-cover'
				/>

				<div className='absolute left-3 top-3 flex h-14 w-14 items-center justify-center rounded-xl bg-white p-1'>
					<img
						width={200}
						height={200}
						src={clientImage}
						alt={client + '-icon'}
						className='h-full w-full rounded-lg'
					/>
				</div>

				{/* tags */}
				{tags.length > 0 && (
					<ul className='absolute bottom-3 left-3 flex h-fit max-h-8 w-full gap-[6px] overflow-x-scroll'>
						{tags.map((tag, index) => (
							<li
								key={index}
								className='rounded-md bg-white px-3 py-1 text-xs uppercase'>
								{tag}
							</li>
						))}
					</ul>
				)}
			</div>
			<div className='flex w-full flex-1 flex-col justify-between rounded-b-xl'>
				{/* header */}
				<div className='flex flex-col gap-1 p-3 text-left'>
					<h3 className='text-xl font-semibold'>{title}</h3>
					<p className='line-clamp-4 text-sm'>{description}</p>
				</div>

				<div className='flex w-full items-center justify-end border-t border-t-stone-200 p-3'>
					<button
						onClick={navigateToProject}
						className='flex w-fit items-center rounded-lg bg-primary-800 px-3 py-2 text-center text-sm font-medium text-white hover:bg-primary-800 hover:opacity-90 focus:outline-none'>
						View more
						<svg
							className='ms-2 h-3.5 w-3.5 rtl:rotate-180'
							aria-hidden='true'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 14 10'>
							<path
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M1 5h12m0 0L9 1m4 4L9 9'
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

const TableHeadItem = (props) => {
	const { title } = props;

	return (
		<th className='whitespace-nowrap border border-l-0 border-r-0 border-solid bg-[#f6f6f6] px-3 py-3 text-center align-middle text-sm font-semibold uppercase'>
			{title}
		</th>
	);
};

const TableCell = (props) => {
	const { children } = props;

	return (
		<td className='border border-l-0 border-r-0 border-solid px-3 py-3 text-center align-middle text-sm'>
			{children}
		</td>
	);
};

const ProjectsTable = () => {
	return (
		<table className='w-full table-auto'>
			<thead>
				<tr>
					<TableHeadItem title='Owner' />
					<TableHeadItem title='Title' />
					<TableHeadItem title='Contractor' />
					<TableHeadItem title='Description' />
					<TableHeadItem title='Location' />
				</tr>
			</thead>
			<tbody>
				{Object.values(otherProjects).map((project) => {
					const {
						id,
						title,
						client,
						location,
						contractor,
						description,
						clientImage,
					} = project;

					return (
						<tr key={id}>
							<TableCell>
								<div className='flex items-center gap-3'>
									<img
										className='h-10 min-h-10 w-10 min-w-10 overflow-hidden rounded-full object-contain'
										src={clientImage || '/placeholder.png'}
									/>
									<h4 className='text-left'>{client}</h4>
								</div>
							</TableCell>
							<TableCell>{title}</TableCell>
							<TableCell>{contractor || '-'}</TableCell>
							<TableCell>{description}</TableCell>
							<TableCell>{location || '-'}</TableCell>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default function AllProjects() {
	const projectsArr = Object.values(projects);

	return (
		<div className='mx-auto min-h-[calc(100vh_-_64px)] w-[80%] p-10'>
			<h3 className='section-title'>Our Projects</h3>

			<div className='my-8 grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-10'>
				{projectsArr.map((project, index) => (
					<ProjectCard
						key={index}
						{...project}
					/>
				))}
			</div>

			<h3 className='section-title mb-5 mt-20'>Other Projects</h3>
			<ProjectsTable />
		</div>
	);
}
