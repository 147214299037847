function ArrowDown(props) {
	const { color = '#fff' } = props;
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='30'
			fill='none'
			viewBox='0 0 30 30'>
			<path
				fill={color}
				d='M7.18 17.55l1.402-1.422 5.851 5.851V6.825h2.03V21.98l5.833-5.851 1.422 1.421-8.27 8.27z'></path>
		</svg>
	);
}

export default ArrowDown;
