import QR from '../../assets/svgs/QR.png';

export const MoreAboutUs = () => {
	return (
		<div className='flex w-full items-center justify-center gap-5 bg-white p-10'>
			<img
				src={QR}
				alt='QR'
				className='h-96 w-96'
			/>

			<div className='w-[50%]'>
				<h3 className='border-l-4 border-primary-700 py-2 pl-4 text-3xl font-semibold text-primary-800'>
					That is a brief about us
				</h3>
				<p className='mb-20 mt-2 text-xl font-semibold text-gray-700'>
					Explore our complete portfolio by scanning the QR code. Learn more
					about our contracting and consultancy services, discover our projects,
					and see how we bring expertise and innovation to every project we
					undertake. From start to finish, we are committed to delivering
					excellence and ensuring our clients' success.
				</p>
			</div>
		</div>
	);
};
