import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';

export default function AppRoutes() {
	return (
		<Routes>
			{routes.map((route, index) => {
				return (
					<Route
						key={index}
						{...route}
					/>
				);
			})}
		</Routes>
	);
}
