import clsx from 'clsx';
import Vision from '../../assets/svgs/vision.svg';
import Mission from '../../assets/svgs/goal.svg';
import Purpose from '../../assets/svgs/purpose.svg';
import SideWave from '../../assets/svgs/side-wave.jsx';

const information = [
	{
		icon: Vision,
		title: 'VISION',
		text: 'To provide services that exceed the expectations of our esteemed clients.',
		className: 'rounded-b-[100px] rounded-t-0 overflow-hidden',
	},
	{
		icon: Mission,
		iconClassName: 'w-[50%] pb-[30%]',
		title: 'MISSION',
		text: 'Our mission is to exceed client expectations by consistently delivering innovative, sustainable, and cost-effective construction solutions. To build long-term relationships with our customers and clients and provide exceptional customer services by pursuing business through innovations and advanced technology.',
		// text: 'بناء علاقات طويلة الأمد مع عملائنا وتقديم خدمات استثنائية من خلال متابعة الأعمال ومساعدة العملاء من خلال الإبتكار والتكنولوجيا المتقدمة.',
	},

	{
		icon: Purpose,
		title: 'PURPOSE',
		iconClassName: 'w-[50%] pb-[30%]',
		text: 'To be a leader in the HVAC, Firefighting, and Piping (Design & Contracting) industry by providing enhanced services, relationships, and profitability.',
		// text: 'أن نكون رائدين في صناعة أنظمة التدفئة والتهوية وتكييف الهواء ومكافحة الحرائق والهندسة الكهربائية والميكانيكية والأنابيب (التصميم والمقاولات) من خلال تقديم خدماتنا على اكمل وجه.',
		className: 'rounded-t-[100px] rounded-b-0 overflow-hidden',
	},
];

const VmoSection = (props) => {
	const { title, text, icon, hasBackground, className, iconClassName } = props;

	const primaryBackground = hasBackground ? 'bg-primary-800 ' : 'bg-white';
	const secondaryBackground = hasBackground ? 'bg-white' : 'bg-primary-800';

	const primaryBackgroundWithGradient = hasBackground
		? '[background:radial-gradient(_89.04%_107.62%_at_87.95%_7.73%,#15513c_0%,#1c295a_28.64%,#1c295a_80%,#03081d_100%_)]'
		: '';

	return (
		<div
			className={clsx(
				className,
				primaryBackgroundWithGradient,
				'relative w-full p-10'
			)}>
			<div
				className={clsx(
					hasBackground
						? '-bottom-40 right-0 rotate-180'
						: '-top-[8rem] left-0',
					'absolute h-full w-[400px]'
				)}>
				<SideWave color={hasBackground ? 'white' : '#1c295a'} />
			</div>

			<div
				className={clsx(
					secondaryBackground,
					'relative z-[0] mx-auto -mb-28 block h-[300px] w-[300px] rounded-full p-1'
				)}>
				<div
					className={clsx(
						primaryBackground,
						'flex h-full w-full justify-center rounded-full'
					)}>
					<img
						alt=''
						src={icon}
						className={clsx(
							'mx-auto flex h-full w-[70%] items-center justify-center rounded-full pb-[25%]',
							iconClassName
						)}
					/>
				</div>
			</div>
			<div
				className={clsx(
					hasBackground ? 'border-white' : 'border-primary-800',
					'relative z-[2] mx-auto flex w-[70%] flex-col items-center justify-center gap-3 rounded-3xl border-2 bg-white p-6 text-primary-800'
				)}>
				<h3 className='text-3xl font-semibold'>{title}</h3>
				<p className='text-xl'>{text} </p>
			</div>
		</div>
	);
};

export const Vmo = () => {
	return (
		<div className='w-full border-b-2 border-t-2 border-white bg-white bg-[radial-gradient(89.04%_107.62%_at_12.05%_7.73%,_#fff_0%,_#fff_28.64%,_#f0f0f0_80%,_#d0d0d0_100%)] bg-repeat text-center'>
			{information.map((info, index) => (
				<VmoSection
					key={index}
					icon={info.icon}
					text={info.text}
					title={info.title}
					className={info.className}
					hasBackground={index % 2 === 0}
					iconClassName={info.iconClassName}
				/>
			))}
		</div>
	);
};
