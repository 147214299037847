export default function UnderConstruction() {
	return (
		<div className='bg-gray-200'>
			<div className='flex h-[calc(100vh_-_64px)] flex-col items-center justify-center'>
				<img
					src='https://www.svgrepo.com/show/426192/cogs-settings.svg'
					alt='Logo'
					className='mb-8 h-52'
				/>
				<h1 className='mb-4 text-center text-4xl font-bold text-primary-800 md:text-5xl lg:text-6xl dark:text-white'>
					Page is under construction
				</h1>
				<p className='mb-8 text-center text-lg text-gray-500 md:text-xl lg:text-2xl dark:text-gray-300'>
					We're currently working hard to get this page live. Stay tuned!
				</p>
			</div>
		</div>
	);
}
