import BackgroundImg from '../../assets/imgs/backgroundImg.png';
import { motion } from 'framer-motion';
import WhiteLogo from '../../assets/svgs/logo-white.svg';
import { Achievements } from './Achievements';
const animation = {
	hidden: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			duration: 0.25,
		},
	},
};

export const Opening = () => {
	return (
		<>
			<div className='relative flex h-[calc(100vh_-_64px)] w-screen'>
				<div className='relative h-[calc(100vh_-_64px)] w-[50vw] rounded-bl-lg rounded-br-lg'>
					<div className='flex h-full w-full flex-col justify-evenly rounded-bl-lg rounded-br-lg'>
						<motion.div
							className='container flex items-center justify-center'
							initial={{ scale: 0 }}
							animate={{ scale: 1 }}
							transition={{
								type: 'spring',
								stiffness: 260,
								damping: 60,
							}}>
							<div className='rounded-md pb-16 pl-20 pr-40 pt-8 font-bold text-white'>
								<img
									alt='logo'
									src={WhiteLogo}
									className='w-[25vw] translate-y-4'
								/>
								<h3 className='mb-2 block translate-x-28 font-poppins text-3xl font-normal leading-none tracking-tighter'>
									<span> Build the </span>
									<div className='typewriter inline-flex items-end'>
										<span className='m-0 inline-block h-full border-l-[3px] border-solid border-primary-700 pb-1 pl-3 pr-4 pt-2 font-Sarah text-2xl font-semibold leading-none tracking-wide text-primary-800 [background:linear-gradient(_90deg,#fff_-5%,rgba(255,255,255,0)_100%_)]'>
											FUTURE
										</span>
									</div>
								</h3>
								<h3 className='block translate-x-40 font-poppins text-3xl font-normal leading-none tracking-tighter'>
									Build with{' '}
									<div className='typewriter inline-flex items-end'>
										<span className='inline-block border-l-[3px] border-solid border-primary-700 pb-1 pl-3 pr-4 pt-2 font-Sarah text-2xl font-semibold leading-none tracking-wide text-primary-800 [background:linear-gradient(_90deg,#fff_-5%,rgba(255,255,255,0)_100%_)]'>
											TRUST
										</span>
									</div>
								</h3>
							</div>
						</motion.div>

						<Achievements />
					</div>
				</div>
				<div className='relative h-[calc(100vh_-_64px)] w-[50vw] rounded-bl-lg rounded-br-lg border-[4px] border-[#1c295a]'>
					<motion.div
						className='relative h-full w-full overflow-hidden rounded-bl-lg rounded-br-lg'
						initial='hidden'
						animate='visible'
						transition={animation}>
						{/* Background image */}
						<img
							className='h-full w-full object-cover'
							src={BackgroundImg}
							alt='background'
						/>
						{/* Gradient overlay */}
						<div className='absolute inset-0 bg-gradient-to-l from-transparent to-[#1c295a]'></div>
					</motion.div>
				</div>
			</div>
			<div
				id='more-sections'
				className='absolute bottom-0 left-0 right-0 z-0'
			/>
		</>
	);
};
