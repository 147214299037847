import clsx from 'clsx';
import { motion } from 'framer-motion';

export const Tab = (props) => {
	const { name, isActive = false, onClick } = props;

	return (
		<button
			onClick={onClick}
			className={clsx(
				isActive ? 'selected' : '',
				'relative border-none px-3 py-1'
			)}>
			{name}
			{isActive ? (
				<motion.div
					layoutId='underline'
					className='absolute bottom-0 left-0 right-0 h-[2px] bg-primary-800'
				/>
			) : null}
		</button>
	);
};
