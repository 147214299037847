import facebook from './../assets/svgs/facebook.svg';
import instagram from './../assets/svgs/instagram.svg';
import linkedIn from './../assets/svgs/linkedin.svg';
import tiktok from './../assets/svgs/tiktok.svg';
import twitter from './../assets/svgs/twitter.svg';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faPhone,
	faLocationDot,
} from '@fortawesome/free-solid-svg-icons';

const SOCIAL_MEDIA = [
	{
		key: 'linkedIn',
		imageSrc: linkedIn,
		link: '',
	},
	{
		key: 'facebook',
		imageSrc: facebook,
		link: '',
	},
	{
		key: 'instagram',
		imageSrc: instagram,
		link: '',
	},
	{
		key: 'tiktok',
		imageSrc: tiktok,
		link: '',
	},
	{
		key: 'twitter',
		imageSrc: twitter,
		link: '',
	},
];

const SocialMedia = () => {
	return (
		<ul className='flex gap-6'>
			{SOCIAL_MEDIA.map((item) => {
				const { key, imageSrc, link } = item;
				return (
					<li key={key}>
						<a
							href={link}
							target='_blank'
							rel='noreferrer'>
							<img
								width={24}
								height={24}
								src={imageSrc}
								alt={key}
							/>
						</a>
					</li>
				);
			})}
		</ul>
	);
};

const Contact = () => {
	return (
		<div className='w-[300px]'>
			<p className='my-1 text-lg'>
				<FontAwesomeIcon
					icon={faPhone}
					className='mr-2 text-[#c2ccdd]'
				/>
				<span className='text-[#c2ccdd]'>+966 563578523 </span>
			</p>

			<p className='my-1 text-lg'>
				<FontAwesomeIcon
					icon={faEnvelope}
					className='mr-2 text-[#c2ccdd]'
				/>
				<span className='text-[#c2ccdd]'>info@SawaedCo.com</span>
			</p>
			{/* <hr className='my-4 border-b border-[#c2ccdd]' /> */}
			<p className='my-1 text-lg'>
				<FontAwesomeIcon
					icon={faLocationDot}
					className='mr-2 text-[#c2ccdd]'
				/>
				<span className='text-[#c2ccdd]'>الرياض - حي المربع - شارع الوشم</span>
			</p>
			{/* <p className='my-1 text-lg'>
				<FontAwesomeIcon
					icon={faLocationDot}
					className='mr-2 text-[#c2ccdd]'
				/>
				<span className='text-[#c2ccdd]'>
					Riyadh - Al Murabba' District Al-Washem St.
				</span>
			</p> */}
		</div>
	);
};

function Footer(props) {
	const { hasBackground = false } = props;

	return (
		<footer
			className={clsx(
				hasBackground ? 'bg-secondary-700' : '',
				'!w-screen p-14'
			)}>
			<p>*logo</p>
			<p>
				<div className='flex flex-row-reverse items-center justify-between gap-4'>
					<SocialMedia />
					<div>
						<p className='text-[#c2ccdd]'>
							© 2024 Sawaed. All rights reserved.
						</p>
					</div>
				</div>
			</p>
			<hr className='my-6 border-b border-[#c2ccdd]' />
			<div className='flex h-full w-full justify-between gap-32'>
				<div className='flex flex-1 flex-col'>
					<em className='mb-0 text-[#c2ccdd]'>
						Construction and trading made better...
					</em>
					<p className='mt-3 text-[#c2ccdd]'>
						Sawaed is dedicated to elevating the construction industry by
						delivering exceptional service, fostering innovation, and building a
						community of excellence. Our commitment to quality and on-time
						delivery ensures that every project we undertake meets the highest
						standards, uniting all stakeholders in a shared vision for success.
					</p>
				</div>
				<div className='min-w-[10%]'>
					<Contact />
				</div>
			</div>
		</footer>
	);
}
export default Footer;
